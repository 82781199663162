import React, { Component } from 'react'
import axios from 'axios';
import './Blog.scss'

const DEFAULT_FEATURED_MEDIA = {
    '0': { source_url: 'https://news.duelyst2.com/wp-content/uploads/2023/04/chapter21_background@2x.jpg' }
};

export class Posts extends Component {
   state = {
       posts: [],
       isLoaded: false
   }

 componentDidMount () {
    axios.get('https://news.duelyst2.com/wp-json/wp/v2/posts/?_embed')
       .then(res => this.setState({
           posts: res.data,
           isLoaded: true
       }))
       .catch(err => console.log(err))
    }

   render() {
       const {posts} = this.state;
       const newArr = posts.slice(0,3);
       return (
           <div className='PostsWrap'>
               {/*posts.map(post => { //For Sticky posts, take out for now
                if(post.sticky === true)
                return <div key={post.id} className='SinglePost Sticky'>
                            <h4><a href={post.link} target="blank" rel="nofollow noopener noreferrer">{post.title.rendered}</a></h4>
                            <hr/>
                            <div className='PostContent' dangerouslySetInnerHTML={{__html:post.excerpt.rendered + "<a href=" + post.link + " target='blank' rel='nofollow noopener noreferrer'>Continue to Full Post</a>"}}></div>
                
                        </div>
                return null
                    })*/}

                {newArr.map(post => {
                //if(post.sticky !== true)
                const featuredMedia = (post._embedded['wp:featuredmedia'] || DEFAULT_FEATURED_MEDIA)['0'];
                return <div key={post.id} className='SinglePost Reg'>
                            <h4 dangerouslySetInnerHTML={{__html: `<a href="${post.link}" target='blank' rel='nofollow noopener noreferrer'>${post.title.rendered}</a>` }}></h4>
                            <div className='featured-image'>
                                <a href={post.link} target="blank" rel="nofollow noopener noreferrer">
                                    <img src={featuredMedia.source_url} alt={featuredMedia.alt_text}></img>
                                </a>
                            </div>
                            <div className='PostContent' dangerouslySetInnerHTML={{__html:post.excerpt.rendered + "<p><a href=" + post.link + " target='blank' rel='nofollow noopener noreferrer'>Continue to Full Post</a></p>"}}></div>
                        </div>
                //return null
                })}
           </div>
       );
   }
}
export default Posts
