import React, { useEffect, useState } from "react";

function CountDown() {
    const calculateTimeLeft = () => {
        let countDownDate = new Date("Dec 16, 2022 17:00:00 UTC+00:00").getTime();
        let now = new Date().getTime();
        const difference = countDownDate - now;
    
        let timeLeft = {};
    
        if (difference >= 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
    
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
    
        timerComponents.push(
        <span>
            {timeLeft[interval] < 10 && interval !== "seconds" ? "0"+ timeLeft[interval]+ " : " 
            : timeLeft[interval] < 10 && interval === "seconds" ? "0"+ timeLeft[interval] + " " 
            : interval !== "seconds" ? timeLeft[interval] + " : "
            : timeLeft[interval]}
        </span>
        );
    });

    return (
    <div id="countdown">
            {/* {
            timerComponents.length ? timerComponents : */}
            <div className="Home-FlexContainer">
                <div className="Home-Button">

                    <a className="join" href="https://play.duelyst2.com">Play On Web</a>
                    
                </div>
                <div className="Home-Button">
                    
                    <a className="join" href="https://store.steampowered.com/app/2004320/Duelyst_II/">Play On Steam</a>
                    
                </div>
            </div>
            <div className="Home-FlexContainer">
                <div className="Home-Button">
                    <a className="join" href="https://news.duelyst2.com/duelyst-ii-the-board-game/">Play On Tabletop</a>
                </div>
            </div>
    </div>
    );
}

export default CountDown;